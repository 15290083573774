import { InvokeCommand, LambdaClient } from "@aws-sdk/client-lambda";


export default async function invokedocumentagent(query,memory,similardocs) {
    try {
        const config = {
            region: 'us-east-1',
            credentials: {
                accessKeyId: process.env.REACT_APP_REALM_AWSID,
                secretAccessKey: process.env.REACT_APP_REALM_AWSKEY
        }}
        const client = new LambdaClient (config);
        // console.log("Client", client)


        const input = { // InvocationRequest
          FunctionName: "arn:aws:lambda:us-east-1:457968394077:function:documentagent", // required
        //   InvocationType: "Event",
          LogType: "Tail",
        //   ClientContext: "STRING_VALUE",
          Payload: JSON.stringify({
                    "query" : query,
                    "memory" : memory,
                    "similardocs" : similardocs
                }),
        //   Qualifier: "STRING_VALUE",
        };

        // console.log("Input", input)


        const command = new InvokeCommand(input);
        // console.log("COmmand", command)


        const response = await client.send(command);
        // console.log(response)
        const payloadString = String.fromCharCode.apply(null, response.Payload);
        // console.log("Lambda Response", payloadString)
        return payloadString
        
    } catch (error) {

        console.log(error)
        
    }
}
