import { Button, Collection, Flex, Text, View } from '@aws-amplify/ui-react';
import { Grid, TextField } from '@mui/material';
import React from 'react'
import getUserChat from '../realm-scripts/getUserChat';
import ReactMarkdown from 'react-markdown';
import "../App.css"
import generalagent from '../langchain/generalagent';
import GetImageUrl from './GetImageUrl';
import { PropagateLoader } from 'react-spinners';
import Modal from 'react-modal'
import {motion} from 'framer-motion'
import ModalGeneralAgent from '../modals/ModalGeneralAgent';
import GoogleSearchModal from './GoogleSearchModal.tsx';
import invokegooglesearch from '../lambda/invokegooglesearch.js';




export default function QueryboxGeneralAgent(props) {

    const [query, setQuery] = React.useState("")
    const [isloading, setisloading] = React.useState(false)

    React.useEffect(() => {
      if(isloading){
        setTimeout(() => {setisloading(false)}, 90000)
      }
    }, [isloading])
    
  

    const currentproject = props.projectname
    const [projectconversation, setprojectconversation] = React.useState([])
    const UserChat = async () => {
      const userschat = await getUserChat(currentproject)

      const convo = userschat.filter(convo => convo.image !== true)
      setprojectconversation(convo)
      }

    React.useEffect(() => {

      if(currentproject === "" || currentproject === null || currentproject === undefined){
        return
      }
      UserChat()
    }, [currentproject])

    const [newquery, setnewquery] = React.useState(false)
    React.useEffect(() => {
      const refreshquerybox = async () => {
        UserChat()
        setnewquery(false)
      }
      refreshquerybox()
    }, [newquery])
    

    const [googleFrame, setgoogleFrame] = React.useState([])
    React.useEffect(() => {
      setgoogleFrame([])
    }, [currentproject])
    
    const QueryGeneralAgent = async () => {
      setisloading(true)
      if(currentproject === "" || currentproject === null || currentproject === undefined){
        setisloading(false);
        window.scrollTo(0,0);
        alert("Select or Create Project!")
        return
        }
        if(query === ""){
            
            setisloading(false)
            alert("Enter query!")
            return
        }
        await generalagent(query, currentproject)
        const data = await invokegooglesearch(query)
      const cleanJSON = JSON.parse(data)
      setgoogleFrame(cleanJSON.body)

        setnewquery(true)
        setisloading(false)
        setQuery(" ")
        window.scrollTo(0,document.body.scrollHeight);
        
    }

    const [showQueryBox, setShowQueryBox] = React.useState(false)
    const closeQueryBox = async () => {
        setShowQueryBox(false)
        UserChat()
    }


    const [searchModal, setSearchModal] = React.useState(false)
    const showGoogleSearch = () => {
      setSearchModal(true)
    }

    const closeSearchModal = () => {
      setSearchModal(false)
    }
    

  return (
    <View
    // position="relative"
    // paddingTop="5px"
    // paddingBottom="15px"
    // display="flex"
        
        className="relative flex flex-col  pt-[80px] pb-[15px] w-full h-[100vh] "
    
    // backgroundColor="pink"
    // width="100%"
    
    >
      <View id={`Sa`} className="relative flex flex-col bg-[rgba(255,255,255,.3)]  w-full h-full  pl-3 pr-3 pb-3 rounded-3xl overflow-scroll">

    {/* Query texfield plus button */}
      <Flex
        // backgroundColor="green"
        width="100%"
        height="auto"
        justifyContent="center"
        alignItems="center"
        direction="column"
        paddingTop="20px"
        paddingBottom="20px"
        gap="10px"
        
        
        >
     
      <TextField
      sx={{
        color: 'white',
        
        // background: "red"
        
      
      }}
      // color='white' 
        position="relative"
        // className="border-red"
        
        // boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
        // backgroundColor="blue"
        
        // height="500px"
       multiline 
       fullWidth
       id="outlined-basic" 
       label="Enter Query ..." 
       variant="outlined" 
      //  className=' text-zinc-100 '
        // className='QueryInput'
        // top='-20px'
        value={query}
        onChange={(e) => {
            let { value } = e.target;
            setQuery(value);
          }}
        height="45px"
      />

<motion.div
        initial={{
            // width : "100%",
        }}
        whileHover={{
          scale : 1.1,
        //   paddingLeft : "25px"
        }}
        whileTap={{
          scale: 0.9,
          
        }}
        
        >
         <Button
         position="relative"
      height="30px"
      color="black"
      className='CollectionCard'
      variation='primary'
      isLoading={isloading}
      boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
    //   isDisabled={dataloaded ? false : true}
    onClick={()=> {
        QueryGeneralAgent()
        
        
    }}
     
      >
        Query
         </Button>

         </motion.div>
    
      </Flex>
     {/* Query texfield plus button */}
     <Flex
     width="100%"
    //  backgroundColor="pink"
     >
     <motion.div
        initial={{
            // width : "100%",
        }}
        whileHover={{
          scale : 1.1,
          // paddingLeft : "2px"
        }}
        whileTap={{
          scale: 0.9,
          
        }}
        
        >
         <Text
         color="white"
         paddingLeft={5}
         fontWeight="bold"
         paddingBottom={5}
         
          
          onClick={showGoogleSearch}>
            Show Related Links!
          </Text>
          </motion.div>
      </Flex>
       
       
    {/* The query box itself */}
    
      <View
      className="relative overflow-x-scroll "
      // container
        
        // 
        // backgroundColor="var(--amplify-colors-white)"
        // backgroundColor="rgba(255,255,255, .6)"
        // backgroundColor="green"
        // // HERE !

        // // backgroundColor="green"
        
        // boxShadow="0px 5px 15px rgba(0, 0, 0, .5)"
        // borderRadius="16px"
        // // color="var(--amplify-colors-blue-60)"
        // // maxHeight="40vh"
        // // minHeight="400px"
        
        // // width="auto"
        // paddingLeft="20px"
        // paddingRight="20px"
        // paddingTop="20px"
        // paddingBottom="20px"
        // direction="column"
        // // width="100%"
        // display="block"
        // sx={{
        //   overflowX:"hidden",
        //   overflowY: "scroll"
        // }}
         
        // // overflow="scroll"
        onClick={() => {
          setShowQueryBox(true)
        }}
        
        // wrap="wrap"
        // style={{
        //   // overflowX:"hidden",
        //   // wra
        
        // }}
        
        

        
       
        // width="500px"
        // onClick={() => alert('🏔 What a beautiful <View>! 🔭')}
        >
<motion.div
        initial={{
            // width : "100%",
        }}
        whileHover={{
          scale : 1,
        //   paddingLeft : "25px"
        }}
        whileTap={{
          scale: 1,
          
        }}
        
        >
          
          

          {/* <Grid container item
          // width="100%"
          height="40px"
          position="relative"
          paddingTop="8px"
          backgroundColor="pink"
          >

          <Text
          position="relative"
          display="basis"
          paddingTop="0px"
          paddingBottom="6px"

          // width="200px"
          // backgroundColor="pink"
          
          style={{
            zIndex: 1,
      

          }}
      
          >

      
          </Text>

          </Grid> */}

{isloading ? 
       <Grid
        container
        display="flex"
        position="relative"
        justifyContent="center"
        alignItems='center'

        width="100%"
        height="310px"
        // paddingRight="50px"
        // paddingTop="40px"
        // backgroundColor="pink"
        >
          <Flex
          justifyContent="flex-start"
          alignItems="center"
          position="relative"
          paddingRight="50px"
          width="40px"
          height="40px"
          // backgroundColor="brown"
          >
          <PropagateLoader
        loading="true"
        // color='#de8865cc'
        color='white'
        size="50px"
        /> 
          </Flex>
        </Grid>
        : 
        
          <Collection
        items={projectconversation || []}
        type="list"
        direction="column-reverse"
        position="relative"
        searchNoResultsFound=" "
        overflow="hidden"
      
        
        // searchPlaceholder="Search Projects..."
        // width="100%"
        // columnGap="15px"
        // isSearchable
        // paddingLeft="20px"
        // paddingRight="20px"
        // paddingTop="10px"
        // paddingBottom="20px"
        // // backgroundColor="pink"
        // overflow="scroll"
        // style={{
        //     zIndex: 8
        // }}
        // isPaginated
        // itemsPerPage={2}  
    >
      {(item, index) => (   
          <Flex
          position="relative"
          // backgroundColor="red"
          direction="column"
          gap="10px"
          paddingTop="0px"
          key={index}
          >

          <Grid container
          position="relative"
          width="100%"
          // backgroundColor="rgba(255,255,255,.8)"
          // backgroundColor="rgba(222,136,101,.8)"
          backgroundColor="grey"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
          padding="10px 10px 10px 10px"
          borderRadius="16px"
          direction="column"
          gap="5px"
          sx={{
            textOverflow:"ellipsis",
            wordWrap: "break-word",
            lineBreak:"anywhere"
          
        }}
          >
          <Text
          // fontSize="15px"
          // backgroundColor="pink"
          color="white"
          >
          Query: 
          </Text>

          <ReactMarkdown
          className={"text-white"}
          >
            
          
          {item.query}
          
          
        
         
          </ReactMarkdown>
          <GetImageUrl img={item.images || []}/>

          </Grid>

          <Grid container
          position="relative"
          width="100%"
          // display="flex"
          // justifyContent="flex-start"
          // backgroundColor="rgba(222,136,101,.9)"
          backgroundColor="rgba(255,255,255,.6)"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
          padding="10px 10px 10px 10px"
          borderRadius="16px"
          overflow="scroll"
          direction="column"
          sx={{
            textOverflow:"ellipsis",
            wordWrap: "break-word"
        }}
          gap="5px"
          >
            <Text
            color="black"
          // backgroundColor="pink"
          >
          Response: 
          </Text>

         
      
          
          {/* {rm.render(item.response)} */}
          
     
          <ReactMarkdown
          components={{
           
            code({node, inline, className, children, ...props}) {
              const match = /language-(\w)/.exec(className || "")
              return !inline && match ? ( 
             
                <Grid container
              position="relative"
              paddingLeft="20px"
              paddingRight="20px"
              fontSize="15px"
              fontWeight="bold"
                  width="auto"
                  wrap="wrap"
                  whiteSpace="pre-wrap"
                  
                  sx={{
                    textOverflow:"ellipsis",
                    wordWrap: "break-word",
                    lineBreak:"anywhere"
                }}
                  
               
                  
                 {... props}>
                

                 
                  {children}
                  
                </Grid> 
            
                ) : <span
                className=' font-bold'
                // position="relative"
                >
                  {children}

                </span>
            }
          }}
          children={item.response}

        
          />
          
            
          
          
          
          
        
         
       
          
          </Grid>

          </Flex>
      )}
          </Collection>
        
}</motion.div>
      </View>
      
    {/* The query box itself */}
      </View>
    <Modal
    isOpen={showQueryBox}
    onRequestClose={closeQueryBox}
    style={{
      overlay: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'flex-start',
      //   alignItems: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,.8)',
        zIndex: 9999,
        paddingLeft: "20px",
        overflow: "scroll",
        paddingTop: "20px",
        paddingBottom: "10px",
        paddingRight: "20px"
      },
      content: {
        position: 'relative',
        display: "block",
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        border: '3px solid grey',
      //   minHeight: "auto",
        maxHeight: "95vh",
        minHeight: "auto",
        // background: 'rgba(222,136,101,.95)',
        background: "grey",
        width: "100%",
      //   overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '15px',
        outline: 'none',
        paddingTop: "0px",
        paddingBottom: "10px",
        paddingLeft: "20px",
        paddingRight: "20px"


      }
    }}
  
    
    >
        
        {/* <Text
        width="100%"
        textAlign="center"
        backgroundColor="red"
        >
            Image Agent
        </Text> */}

       <ModalGeneralAgent 
       projectname={currentproject}
       closeQueryBox={closeQueryBox}
       />

    </Modal>           

    <Modal
    isOpen={searchModal}
    onRequestClose={closeSearchModal}
    style={{
      overlay: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'flex-start',
      //   alignItems: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,.8)',
        zIndex: 9999,
        paddingLeft: "20px",
        overflow: "scroll",
        paddingTop: "20px",
        paddingBottom: "10px",
        paddingRight: "20px"
      },
      content: {
        position: 'relative',
        display: "flex",
        justifyContent: "flex-start",
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        border: '0px solid grey',
        height: "500px",

        // minHeight: "auto",
        // maxHeight: "95vh",
        // minHeight: "auto",
        background: 'grey',
        width: "auto",
        overflow: 'hidden',
        
        // WebkitOverflowScrolling: 'touch',
        borderRadius: '15px',
        outline: 'none',
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        paddingRight: "0px"


      }
    }}
  
    
    >
      <GoogleSearchModal 
      googleFrame={googleFrame}/>
    </Modal>
   
     </View>
  )
}
