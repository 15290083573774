import React from 'react'
import dummyagent from '../langchain/dummyagent'
import getUserChat from '../realm-scripts/getUserChat'
import { Button, Collection, Flex, Text, View } from '@aws-amplify/ui-react'
import { Grid, TextField } from '@mui/material'
import ReactMarkdown from 'react-markdown';
import GetImageUrl from './GetImageUrl'
import { PropagateLoader } from 'react-spinners'
import Modal from 'react-modal'
import {motion} from 'framer-motion'
import ModalDummyAgent from '../modals/ModalDummyAgent'

export default function QueryboxDummyAgent(props) {
    const [query, setQuery] = React.useState("")
    const [isloading, setisloading] = React.useState(false)

    const currentproject = props.projectname
    const [projectconversation, setprojectconversation] = React.useState([])
    const UserChat = async () => {
      const userschat = await getUserChat(currentproject)
      // console.log("User's Project Chat", userschat)

      const convo = userschat.filter(convo => convo.image !== true)
      setprojectconversation(convo)
      }
    React.useEffect(() => {
      // console.log("Current Project", currentproject)
      if(currentproject === "" || currentproject === null || currentproject === undefined){
        return
      }
      UserChat()
    }, [currentproject])

    const [newquery, setnewquery] = React.useState(false)
    React.useEffect(() => {
      // console.log("New query triggered", newquery)
      const refreshquerybox = async () => {
        UserChat()
        setnewquery(false)
        // setisloading(false)
      }
      refreshquerybox()
    }, [newquery])
    

    const QueryDummyAgent = async () => {
      setisloading(true)
      if(currentproject === "" || currentproject === null || currentproject === undefined){
        setisloading(false);
        window.scrollTo(0,0);
        alert("Select or Create Project!")
        // console.log("Select Project");
        return
        }
        if(query === ""){
            
            setisloading(false)
            alert("Enter query!")
            // console.log("Query")
            return
        }
        await dummyagent(query, currentproject)
        setnewquery(true)
        setisloading(false)
        setQuery(" ")
        window.scrollTo(0,document.body.scrollHeight);
        
    }

    const [showQueryBox, setShowQueryBox] = React.useState(false)
    const closeQueryBox = async () => {
        setShowQueryBox(false)
        UserChat()
    }

  return (
    <View
    
        className="relative flex flex-col  pt-[80px] pb-[15px] w-full h-[100vh] "
    
    // backgroundColor="pink"
  
    
    >
      <View id={`Da`} className="relative flex flex-col bg-[rgba(255,255,255,.3)]  w-full h-full  pl-3 pr-3 rounded-3xl overflow-scroll pb-3">
    {/* Query texfield plus button */}
      <Flex
        // backgroundColor="green"
        width="100%"
        height="auto"
        justifyContent="center"
        alignItems="center"
        direction="column"
        paddingTop="20px"
        paddingBottom="20px"
        gap="10px"
        >
     
      <TextField
        position="relative"
        // className="border-red"
        // color="success"
        // boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
        // backgroundColor="blu  e"
        
        // height="500px"
       multiline
       fullWidth
       
       id="outlined-basic" 
       label="Enter Query ..." 
       variant="outlined" 
      //  color='white'
      //  color='white'
      // color="secondary"
      
      
        className=' text-[rgba(255,255,255,1)]'
        // top='-20px'
        value={query}
        onChange={(e) => {
            let { value } = e.target;
            setQuery(value);
          }}
        height="85px"
      />

<motion.div
        initial={{
            // width : "100%",
        }}
        whileHover={{
          scale : 1.1,
        //   paddingLeft : "25px"
        }}
        whileTap={{
          scale: 0.9,
          
        }}
        
        >

         <Button
         position="relative"
      height="30px"
      color="black"
      className='CollectionCard'
      variation='primary'
      isLoading={isloading}
      boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
    //   isDisabled={dataloaded ? false : true}
    onClick={()=> {
        QueryDummyAgent()
       
    }}
     
      >
        Query
         </Button>

         </motion.div>
    
      </Flex>
    {/* Query texfield plus button */}
       
     {/* The query box itself */}
    
      <View
        className="relative overflow-x-scroll "
        onClick={() => {
          setShowQueryBox(true)
        }}
        

        >

 {/* <motion.div
        initial={{
            // width : "100%",
        }}
        whileHover={{
          scale : 1.0,
        //   paddingLeft : "25px"
        }}
        whileTap={{
          scale: .98,
          
        }}
        
        > */}
      
        {isloading ? 
        <Grid
        container
        display="flex"
        position="relative"
        justifyContent="center"
        alignItems='center'

        width="100%"
        height="310px"
    
        // backgroundColor="pink"
        >
          <Flex
          justifyContent="flex-start"
          alignItems="center"
          position="relative"
          paddingRight="50px"
          width="40px"
          height="40px"
          // backgroundColor="brown"
          >
          <PropagateLoader
        loading="true"
        // color='#de8865cc'
        color='white'
        size="50px"
        /> 
          </Flex>
        </Grid>
        : 
          <Collection
        items={projectconversation || []}
        type="list"
        direction="column-reverse"
        position="relative"
        searchNoResultsFound=" "
        overflow="hidden"
        // searchPlaceholder="Search Projects..."
        // width="100%"
        // columnGap="15px"
        // isSearchable
        // paddingLeft="20px"
        // paddingRight="20px"
        // paddingTop="10px"
        // paddingBottom="20px"
        // backgroundColor="pink"
        // overflow="scroll"
        // style={{
        //     zIndex: 8
        // }}
        // isPaginated
        // itemsPerPage={2}  
    >
      {(item, index) => (   
          <Flex
          position="relative"
          // backgroundColor="pink"
          direction="column"
          gap="10px"
          paddingTop="0px"
          key={index}
          >

<Grid container
          position="relative"
          width="100%"
          // backgroundColor="rgba(255,255,255,.8)"
          // backgroundColor="rgba(222,136,101,.8)"
          backgroundColor="grey"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
          padding="10px 10px 10px 10px"
          borderRadius="16px"
          direction="column"
          gap="5px"
          sx={{
            textOverflow:"ellipsis",
            wordWrap: "break-word",
            lineBreak:"anywhere"
          
        }}
          >
          <Text
          // fontSize="15px"
          // backgroundColor="pink"
          color="white"
          >
          Query: 
          </Text>

          <ReactMarkdown
          className={"text-white"}
          >
            
          
          {item.query}
          
          
        
         
          </ReactMarkdown>
          <GetImageUrl img={item.images || []}/>

          </Grid>

          

          <Grid container
          position="relative"
          width="100%"
          // display="flex"
          // justifyContent="flex-start"
          // backgroundColor="rgba(222,136,101,.9)"
          backgroundColor="rgba(255,255,255,.6)"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
          padding="10px 10px 10px 10px"
          borderRadius="16px"
          overflow="scroll"
          direction="column"
          sx={{
            textOverflow:"ellipsis",
            wordWrap: "break-word"
        }}
          gap="5px"
          >
            <Text
            color="black"
          // backgroundColor="pink"
          >
          Response: 
          </Text>

          <ReactMarkdown
          components={{
           
            code({node, inline, className, children, ...props}) {
              const match = /language-(\w)/.exec(className || "")
              return !inline && match ? ( 
             
                <Grid container
              position="relative"
              paddingLeft="20px"
              paddingRight="20px"
              fontSize="15px"
              fontWeight="bold"
                  width="auto"
                  wrap="wrap"
                  whiteSpace="pre-wrap"
                  
                  sx={{
                    textOverflow:"ellipsis",
                    wordWrap: "break-word",
                    lineBreak:"anywhere"
                }}
                  
               
                  
                 {... props}>
                

                 
                  {children}
                  
                </Grid> 
            
                ) : <span
                className=' font-bold'
                // position="relative"
                >
                  {children}

                </span>
            }
          }}
          children={item.response}

        
          />
        
          
          </Grid>

          </Flex>
      )}
          </Collection>
          } 
          {/* </motion.div> */}
          
      </View>
    
</View>
    {/* The query box itself */}
    <Modal
    isOpen={showQueryBox}
    onRequestClose={closeQueryBox}
    style={{
      overlay: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'flex-start',
      //   alignItems: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,.8)',
        zIndex: 999,
        paddingLeft: "20px",
        overflow: "scroll",
        paddingTop: "20px",
        paddingBottom: "10px",
        paddingRight: "20px"
      },
      content: {
        position: 'relative',
        display: "block",
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        border: '3px solid grey',
      //   minHeight: "auto",
        height: "95vh",
        // background: 'rgba(222,136,101,.95)',
        // background: "rgba(222,136,101,1)",
        background: "grey",
        width: "100%",
      //   overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '15px',
        outline: 'none',
        paddingTop: "0px",
        paddingBottom: "10px",
        paddingLeft: "20px",
        paddingRight: "20px"


      }
    }}
  
    >
        
       

       <ModalDummyAgent 
       projectname={currentproject}
       closeQueryBox={closeQueryBox}
       />

    </Modal>
      
      
   
     </View>
  )
}
