import React from 'react'
import getUserChat from '../realm-scripts/getUserChat'
import { Button, Collection, Flex, Image, Text, View } from '@aws-amplify/ui-react'
import { Grid, TextField } from '@mui/material'
import ReactMarkdown from 'react-markdown';
import { PropagateLoader } from 'react-spinners'
import invokegimageagent from '../lambda/invokegimageagent'
import getAuthusercreds from '../aws_utils/getAuthusercreds';
import Modal from 'react-modal'
import StorageGet from '../aws_utils/s3storageget';
import { motion } from 'framer-motion'
import ModalImageAgent from '../modals/ModalImageAgent';

export default function QueryboxImageAgent(props) {

    const [query, setQuery] = React.useState("")
    const [isloading, setisloading] = React.useState(false)
    const currentproject = props.projectname
    const [projectconversation, setprojectconversation] = React.useState([])


    const UserChat = async () => {
        const userschat = await getUserChat(currentproject)
    //   console.log("User's Project Chat", userschat)
        const imgconversations = userschat.filter(img => img.image === true)
    // console.log("imgconvo", imgconversations)
        setprojectconversation(imgconversations)
      }

    const [cogid, setcogid] = React.useState('')
    React.useEffect(() => {
      const GetCogId = async () => {
      try {
          const id = await getAuthusercreds()
          const identityId = id.identityId
        //   console.log("id", id.identityId)
          setcogid(identityId)
      } catch (error) {
          console.log(error)
      }
      }
      GetCogId()
    }, [])
    
    
    React.useEffect(() => {
      // console.log("Current Project", currentproject)
      if(currentproject === "" || currentproject === null || currentproject === undefined){
        return
      }
      UserChat()
    }, [currentproject])

    const [newquery, setnewquery] = React.useState(false)
    React.useEffect(() => {
      // console.log("New query triggered", newquery)
      const refreshquerybox = async () => {
        UserChat()
        setnewquery(false)
        // setisloading(false)
      }
      refreshquerybox()
    }, [newquery])

   
    

    const QueryImageAgent = async () => {
      setisloading(true)
      if(currentproject === "" || currentproject === null || currentproject === undefined){
        setisloading(false);
        window.scrollTo(0,0);
        alert("Select or Create Project!")
        // console.log("Select Project");
        return
        }
        if(query === ""){
            
            setisloading(false)
            alert("Enter query!")
            // console.log("Query")
            return
        }

        await invokegimageagent(query, currentproject, cogid)

        setnewquery(true)
        setisloading(false)
        setQuery(" ")
        window.scrollTo(0,document.body.scrollHeight);
        
    }

    const [showdata, setshowdata] = React.useState(false)
    const [currentkey, setcurrentkey] = React.useState("")
    const closeModal = async () => {
        setshowdata(false)
    }

    const [showQueryBox, setShowQueryBox] = React.useState(false)
    const closeQueryBox = async () => {
        setShowQueryBox(false)
        UserChat()
    }
    
    const DisplayImage = (key) => {
     

        const [url, seturl] = React.useState("")
        // React.useEffect(() => {
        // console.log("Image key", props.image_key)
        
        // let url = ""
        const getUrl = async (key) => {
            try {

               const get_url = await StorageGet(key.image_key)
                // console.log("Get Url", get_url)
                seturl(get_url)
                // seturl({...url, get_url})
            } catch (error) {
                console.log("Error", error)
            }
        
        // 
        }
        getUrl(key)

       
          // console.log("Url Gotten", url)
        // }, [props.image_key])
        
        

        return (
          <View className="relative flex w-full items-center justify-center ">
        <Image
             src={url} 
             position="relative"
         objectFit="contain"
        //  backgroundColor="red"
         width="80%"
         maxHeight="400px"
        //  height=""
         onClick={() => {
            setcurrentkey(url)
            setshowdata(true)
         }}
             /></View>)
    }





  return (
     <View
    // position="relative"
    // paddingTop="5px"
    // paddingBottom="15px"
    // display="flex"
        
        className="relative flex flex-col  pt-[80px] pb-[15px] w-full h-[100vh] "
    
    // backgroundColor="pink"
    // width="100%"
    
    >
      <View id={`Ia`} className="relative flex flex-col bg-[rgba(255,255,255,.3)]  w-full h-full  pl-3 pr-3 rounded-3xl overflow-scroll pb-3 ">
   

    {/* Query texfield plus button */}
      <Flex
        // backgroundColor="green"
        width="100%"
        height="auto"
        justifyContent="center"
        alignItems="center"
        direction="column"
        paddingTop="20px"
        paddingBottom="20px"
        gap="10px"
        >
     
      <TextField
        position="relative"
        // className="border-red"
        // color="success"
        // boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
        // backgroundColor="blu  e"
        
        // height="500px"
       multiline
       fullWidth
       
       id="outlined-basic" 
       label="Enter Query ..." 
       variant="outlined" 
      //  color='white'
      //  color='white'
      // color="secondary"
      
      
        className=' text-[rgba(255,255,255,1)]'
        // top='-20px'
        value={query}
        onChange={(e) => {
            let { value } = e.target;
            setQuery(value);
          }}
        height="85px"
      />

        <motion.div
        initial={{
            // width : "100%",
        }}
        whileHover={{
          scale : 1.1,
        //   paddingLeft : "25px"
        }}
        whileTap={{
          scale: 0.9,
          
        }}
        
        >
{/* <a href="#Da"> */}
         <Button
         position="relative"
      height="30px"
      color="black"
      className='CollectionCard'
      variation='primary'
      isLoading={isloading}
      boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
    //   onMouseEnter={() => {
    //     QueryImageAgent()    
    //   }}
    //   isDisabled={dataloaded ? false : true}
    onClick={()=> {
        QueryImageAgent()    

    }
   
}
     
      >
        Query
         </Button>
 {/* </a> */}
         </motion.div>
    
      </Flex>
    {/* Query texfield plus button */}
       
     {/* The query box itself */}
     
    
      <View 
     className="relative overflow-x-scroll "
        onClick={() => {
            setShowQueryBox(true)
        }}
        
        >
 {/* <motion.div
        initial={{
            // width : "100%",
        }}
        whileHover={{
          scale : 1.01,
        //   paddingLeft : "25px"
        }}
        whileTap={{
          scale: 1,
          
        }}
        
        >
       */}
{isloading ? 
        <Grid
        container
        display="flex"
        position="relative"
        justifyContent="center"
        alignItems='center'

        width="100%"
        height="310px"
        // paddingRight="50px"
        // paddingTop="40px"
        // backgroundColor="pink"
        >
          <Flex
          justifyContent="flex-start"
          alignItems="center"
          position="relative"
          paddingRight="50px"
          width="40px"
          height="40px"
          // backgroundColor="brown"
          >
          <PropagateLoader
        loading="true"
        // color='#de8865cc'
        color='white'
        size="50px"
        /> 
          </Flex>
        </Grid>
        : 
       
          <Collection
        items={projectconversation || []}
        type="list"
        direction="column-reverse"
        position="relative"
        searchNoResultsFound=" "
        // searchPlaceholder="Search Projects..."
        // width="100%"
        // columnGap="15px"
        // isSearchable
        // paddingLeft="20px"
        // paddingRight="20px"
        // paddingTop="10px"
        // paddingBottom="20px"
        // backgroundColor="pink"
        // overflow="scroll"
        // style={{
        //     zIndex: 8
        // }}
        // isPaginated
        // itemsPerPage={2}  
    >
      {(item, index) => (   
          <Flex
          position="relative"
          // backgroundColor="pink"
          direction="column"
          gap="10px"
          paddingTop="0px"
          key={index}
          >

          <Flex
          position="relative"
          width="100%"
          // backgroundColor="rgba(222,136,101,.8)"
          backgroundColor="grey"
          boxShadow="0px 5px 15px rgba(0, 0, 0, .4)"
          padding="10px 10px 10px 10px"
          borderRadius="16px"
          direction="column"
          gap="5px"
          >
          <Text
          // fontSize="15px"
          // backgroundColor="pink"
          color="white"
          >
          Query: 
          </Text>

          <ReactMarkdown
          className={"text-white"}
          >
            
          
          {item.query}
          
          
        
         
          </ReactMarkdown>
          {/* <GetImageUrl img={item.image}/> */}

          </Flex>

          

          <Flex
          position="relative"
          width="100%"
          backgroundColor="rgba(255,255,255,.6)"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
          padding="10px 10px 10px 10px"
          borderRadius="16px"
          direction="column"
          gap="5px"
          >
            <Text
            color="black"
          // backgroundColor="pink"
          >
          Response: 
          </Text>


          <ReactMarkdown
          className={"text-black"}
          >
            
          
          {item.response}
          
          
          
        
         
          </ReactMarkdown>

          <DisplayImage image_key={item.image_url} />

            {/* <Image
             src={item.image_url} 
             position="relative"
         objectFit="cover"
        //  backgroundColor="red"
         width="100%"
         height="100%"
         onClick={() => {
            setcurrentkey(item.image_url)
            setshowdata(true)
         }}

             
             /> */}
          
          </Flex>

          </Flex>
      )}
          </Collection>
         
          }
        
 {/* </motion.div> */}
      </View>
     
    </View>
    {/* The query box itself */}
       <Modal
    isOpen={showdata}
    onRequestClose={closeModal}
    style={{
        overlay: {
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          zIndex: 999,
        },
        content: {
          position: 'relative',
        //   display
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          border: '1px solid #ccc',
          height: "auto",
        //   background: 'blue',
          width: "302px",
        //   overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '0px'
        }
      }}
    
    >
        <View
        display="block"
        position="relative"
        // backgroundColor="pink"
        // width="300px"
        // height="30px"
        // justifyContent="center"
    // backgroundColor="yellow"
        // paddingTop="30px"
    >

       

        <Image
         src={currentkey}
         position="relative"
         objectFit="cover"
        //  backgroundColor="red"
         width="100%"
         height="100%"
        />
        


        </View> 

       </Modal>

       <Modal
    isOpen={showQueryBox}
    onRequestClose={closeQueryBox}
    style={{
        overlay: {
          position: 'fixed',
          display: 'flex',
          justifyContent: 'flex-start',
        //   alignItems: 'center',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255,255,255,.8)',
          zIndex: 999,
          paddingLeft: "20px",
          overflow: "scroll",
          paddingTop: "20px",
          paddingBottom: "10px",
          paddingRight: "20px"
        },
        content: {
          position: 'relative',
          display: "block",
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          border: '3px solid grey',
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)",
        //   minHeight: "auto",
          minHeight: "auto",

        maxHeight: "95vh",
          background: 'grey',
          width: "100%",
          overflow: "hidden",
          WebkitOverflowScrolling: 'touch',
          borderRadius: '15px',
          outline: 'none',
          paddingTop: "0px",
          paddingBottom: "20px",
          paddingLeft: "20px",
          paddingRight: "20px"


        }
      }}
    
    >
        
        {/* <Text
        width="100%"
        textAlign="center"
        backgroundColor="red"
        >
            Image Agent
        </Text> */}
   


       <ModalImageAgent 
       projectname={currentproject}
       closeQueryBox={closeQueryBox}
       />

       </Modal>

      
   
     </View>
  )
}